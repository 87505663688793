import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import type { InitialReservation, Reservation } from '~/types/reservations';

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const consoleInfo = (message: string, ...arg: any[]) => {
  console.log(
    `%c ${message}`,
    'color: #fff; background-color: #3490dc; padding: 3px 4px; border-radius: 3px; font-size: 1.1em;',
    ...arg
  );
};

export const consoleWarn = (message: string, ...arg: any[]) => {
  console.log(
    '%c ' + message,
    'color: #fff; background-color: #f6993f; padding: 3px 4px; border-radius: 3px; font-size: 1.1em;',
    ...arg
  );
};

export const readableFileSize = (bytes: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

  if (bytes === 0) return '0 B';

  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = (bytes / Math.pow(1024, i)).toFixed(2);

  return `${size} ${units[i]}`;
};

export const clearReservationCreateForm = async <T>(formData: InitialReservation) => {
  const { ...rest } = formData;

  const keysToRemove: Array<keyof Reservation> = [
    'is_today',
    'paid_formatted',
    'isCancelled',
    'flag_arrival_title',
  ];

  const filteredRest = Object.fromEntries(
    Object.entries(rest).filter(([key]) => !keysToRemove.includes(key as keyof Reservation))
  );

  return {
    ...filteredRest,
  } as T;
};

export const clearReservationForm = async <T>(formData: Reservation) => {
  const { agency, hotel, unit, ...rest } = formData;

  consoleInfo('Filtered Reservation Form Data:', formData);

  const keysToRemove: Array<keyof Reservation> = [
    'isToday',
    'paidFormatted',
    'isCancelled',
    'flagArrivalTitle',
  ];

  const filteredRest = Object.fromEntries(
    Object.entries(rest).filter(([key]) => !keysToRemove.includes(key as keyof Reservation))
  );

  return {
    ...filteredRest,
    agencyId: agency?.id,
    hotelId: hotel?.id,
    unitId: unit?.id,
  } as T;
};
